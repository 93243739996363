<template>
  <div class="h-100 d-flex flex-column align-items-center">
    <img
      :src="src"
      alt=""
    >
    <div
      class="info-container"
      style="flex:1;"
    >
      <div class="circle" />
      <div class="title">
        {{ title }}
      </div>
      <div
        v-for="item in desc"
        :key="item"
        class="desc"
      >
        · {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'

export default @Component({})
class ScienceItemCard extends Vue {
  @Prop({ required: true }) src
  @Prop({ required: true }) title
  @Prop({
    required: true,
    type: Array
  }) desc
}
</script>

<style lang="scss" scoped>

.info-container {
  border: 1px solid #e49100;
  margin-top: 2rem;
  padding: 1rem;
  position: relative;

  .circle {
    position: absolute;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: #e49100;
    right: 1rem;
    top: -0.5rem
  }

  .title {
    height: 2rem;
    font-size: 1rem;
    text-align: center;
    margin-bottom: 10px;
    font-weight: 500;
    line-height: 1.1;
    color: #e49100;
  }

  .desc {
    text-align: left;
    color: #7f7f7f;
    margin-top: 20px;
    font-size: 13px;
    line-height: 1.42857;
  }
}
</style>
