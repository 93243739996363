<template>
  <div>
    <div class="in-container">
      <carousel />
    </div>
    <div class="d-flex flex-column align-items-center items container">
      <div class="title">
        ABOUT YAFO LIFE SCIENCES
      </div>
      <div class="divider">
        <img
          src="@/assets/images/divider.png"
          alt=""
        >
      </div>
      <div class="content text-center">
        <p>
          YAFO Life Sciences (YLS) is the healthcare division of YAFO Capital, which is focused on helping leading U.S.
          and European biopharma and Medtech companies successfully enter the Asian market, especially China and Japan.
          YLS also helps domestic pharma companies in global market research, targeting, and transactions. Along with
          our local partners, YLS is dedicated to bridging the gap between global medical technologies and the Asian
          market. We also represent leading Chinese pharma and biotech companies to explore licensing/partnership and
          acquisition opportunities globally.
        </p>
        <p>
          Our core business is to help our clients understand the local regulatory and market environment, identify the
          best local strategic partners and advise them in licensing and financing deals to enter the Chinese/Japanese
          or overseas market successfully.
        </p>
        <p>
          YAFO team consists of over 20 on the ground professionals with diverse Wall Street and healthcare industry
          background performing formulating strategy, execution, due diligence and communication work on behalf of our
          clients in China and Japan. Our professional team offers cross-cultural expertise while navigating entry into
          Asian markets and access to additional resources and funding.
        </p>
        <p>
          Over the last decade, we have built strong relationships with the top 200+ pharma and Medtech companies in
          China, successfully helped many of our global clients to raise capital and license-out their products in the
          Chinese market.
        </p>
        <b-row>
          <b-col
            cols="12"
            sm="4"
            class="mt-4"
          >
            <science-item-card
              src="/images/science/consulting.jpeg"
              title="Global Assets Out licensing to China"
              :desc="['Global innovative assets out licensing for China','Prepare pitching material and align on target list','Extensive transaction expertise','Support negotiation']"
            />
          </b-col>
          <b-col
            cols="12"
            sm="4"
            class="mt-4"
          >
            <science-item-card
              src="/images/science/financing.jpg"
              title="China Buy-side Research And Transaction Advisory"
              :desc="['Research global assets in targeted area','Reach out to key contacts','Facilitate cooperation interest','Support negotiations']"
            />
          </b-col>
          <b-col
            cols="12"
            sm="4"
            class="mt-4"
          >
            <science-item-card
              src="/images/science/licensing.jpeg"
              title="China Innovative Assets Out Licensing"
              :desc="['China innovative assets out licensing for ex-China','Prepare pitching material and align on target list','Extensive transaction expertise','Support negotiation']"
            />
          </b-col>
        </b-row>
      </div>
    </div>
    <!--    <div class="d-flex flex-column align-items-center items container">-->
    <!--      <div class="title">-->
    <!--        Deal Process-->
    <!--      </div>-->
    <!--      <div class="divider">-->
    <!--        <img-->
    <!--          src="@/assets/images/divider.png"-->
    <!--          alt=""-->
    <!--        >-->
    <!--      </div>-->
    <!--      <div class="content text-center">-->
    <!--        <p>-->
    <!--          <img-->
    <!--            src="@/assets/images/science/life-1.png"-->
    <!--            alt=""-->
    <!--          >-->
    <!--        </p>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="d-flex flex-column align-items-center items container">
      <div class="title">
        Recent Transaction
      </div>
      <div class="divider">
        <img
          src="@/assets/images/divider.png"
          alt=""
        >
      </div>
      <div class="content text-center">
        <b-row>
          <b-col
            v-for="item in trackRecords"
            :key="item.id"
            cols="6"
            sm="2"
            class="mt-4 transaction-container"
            :class="{animation:!!item.overview}"
          >
            <div
              class="content"
            >
              <img
                class="cover"
                :src="item.image.url"
                alt=""
              >
              <div
                v-if="item.overview"
                class="transaction-summary"
              >
                <div class="title">
                  Transaction Overview
                </div>
                <div class="overview">
                  {{ item.overview }}
                </div>
                <div class="more">
                  <a
                    target="_blank"
                    :href="item.link"
                  >Read More
                    <b-icon icon="chevron-right" />
                  </a>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center items container">
      <div class="title">
        Business Network
      </div>
      <div class="divider">
        <img
          src="@/assets/images/divider.png"
          alt=""
        >
      </div>
      <div class="content text-center">
        <p>
          We have established a sizeable and systematic network which enables us to reach out medical companies, funds,
          industrial professionals that are active in every subsector. Our contacts include hundreds of senior
          executives, heads of business and research development of the leading 100+ pharma and medical companies.
        </p>
        <b-row>
          <b-col
            v-for="(item, index) in new Array(16)"
            :key="index"
            cols="12"
            sm="3"
            class="mt-4"
          >
            <img
              :src="`/images/science/network/Picture${index+1}.png`"
              alt=""
            >
          </b-col>
        </b-row>
      </div>
      <div class="d-flex flex-column align-items-center items container">
        <div class="title">
          Conferences / Events
        </div>
        <div class="divider">
          <img
            src="@/assets/images/divider.png"
            alt=""
          >
        </div>
        <div class="content w-100">
          <p>
            • Host conferences and roadshows for Pharma and Biotech companies to meet potential licensing partners.
          </p>
          <p>
            • Representing clients in China, attending conferences and trade shows to meet partners/investors
          </p>
        </div>
        <div class="news-container">
          <external-link
            v-for="item in news"
            :key="item.id"
            :external="item.content_url"
            :to="{name:'Event Detail',params:{id:item.id}}"
            class="media news"
          >
            <img
              v-if="item.cover"
              :src="item.cover.url | getSource"
              alt=""
            >
            <div class="media-body">
              <div class="title">
                {{ item.title }}
              </div>
              <div class="time">
                <b-icon icon="calendar-check" />
                {{ item.time }}
              </div>
              <div class="content">
                {{ item.introduction }}
              </div>
            </div>
          </external-link>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center items container">
        <div class="title">
          YAFO Life Sciences Execution Team
        </div>
        <div class="divider">
          <img
            src="@/assets/images/divider.png"
            alt=""
          >
        </div>
        <div class="content w-100">
          <b-row>
            <b-col
              v-for="member in members"
              :key="member.id"
              cols="12"
              sm="6"
            >
              <b-row class="member mb-4">
                <b-col>
                  <div>
                    <img
                      class="avatar"
                      :src="member.avatar.url | getSource"
                      alt=""
                    >
                  </div>
                  <div class="text-center mt-2">
                    <div class="name">
                      {{ member.name }}
                    </div>
                    <div class="position">
                      {{ member.position }}
                    </div>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  sm="8"
                >
                  <member-introduction
                    :bold-first="true"
                    :introduction="member.introduction"
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center items container">
      <div class="content text-center">
        <b-row class="w-100">
          <b-col
            cols="12"
            sm="4"
          >
            <img
              src="@/assets/images/science/awards.png"
              alt=""
            >
          </b-col>
          <b-col
            cols="12"
            sm="8"
          >
            <div class="text-center awards-title mt-2">
              YAFO Life Sciences Awards
            </div>
            <div class=" mt-1 awards text-center text-sm-left">
              <div class="mt-2">
                <i class="fas el-icon-fa-star" /> China BD Innovation Award, 2019
              </div>
              <div class="mt-2">
                <i class="fas el-icon-fa-star" /> Global BD Transaction Award, 2020
              </div>
              <div class="mt-2">
                <i class="fas el-icon-fa-star" /> Crossborder BD Transaction Contribution Award, 2021
              </div>
              <div class="mt-2">
                <i class="fas el-icon-fa-star" /> Global BD Transaction Award, 2022
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import Carousel from '@/components/Carousel'
import ExternalLink from '@/components/ExternalLink'
import ScienceItemCard from '@/components/ScienceItemCard'
import MemberIntroduction from '@/components/MemberIntroduction'
import { getTrackRecords } from '@/api/other'
import { getSciencesTeam } from '@/api/team'
import { getList } from '@/api/event'

export default @Component({
  components: {
    Carousel,
    ScienceItemCard,
    ExternalLink,
    MemberIntroduction
  }
})
class Science extends Vue {
  trackRecords = []
  members = []
  news = []

  mounted () {
    this.getTeam()
    this.getNews()
    this.getTrackRecords()
  }

  async getNews () {
    this.news = await getList({
      type: 'upcoming',
      _start: 0,
      _limit: 1
    })
  }

  async getTrackRecords () {
    this.trackRecords = await getTrackRecords()
  }

  async getTeam () {
    const data = await getSciencesTeam()
    this.members = data
  }
}
</script>

<style lang="scss" scoped>

.transaction-container {

  .content {
    overflow: hidden;
    position: relative;
  }

  .cover {
    transition: transform 0.2s;
    transition-timing-function: linear;
    position: relative;
    z-index: 1000;
  }

  .transaction-summary {
    transition: left, right 0.2s;
    transition-timing-function: linear;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 0.875rem;
    background: #3D3D3D;
    color: white;
    white-space: pre-line;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    z-index: 999;

    .title {
      width: 100%;
      font-weight: bold;
      font-size: 15px;
    }

    .overview {
      width: 100%;
      font-size: 12px;
    }

    .more {
      width: 100%;
      font-size: 12px;

      a {
        color: white;
      }
    }
  }

  @media (min-width: 576px) {
    @for $i from 0 through 5 {
      $border-width: 30px;
      $change-percent: 100%;
      &:nth-child(6n + #{$i + 1}) {
        &.animation {
          .content {
            &:hover {
              overflow: unset;

              .cover {
                z-index: 1002;
                transform: translateX(calc(#{- $i * $border-width} - #{$i * $change-percent}));
                //transform: translateX(calc(-30px - 100% ));

              }

              .transaction-summary {
                z-index: 1001;
                left: calc(#{- $i * $border-width  } + #{(1 - $i) * $change-percent});
                right: calc(-#{(5- $i) * $border-width} - #{(5 - $i) * $change-percent});
              }
            }
          }
        }
      }
    }
  }

}

.awards {
  font-size: 15px;

  &-title {
    font-size: 1rem;
    color: #e49100;
    font-weight: bold;
  }

  i {
    color: #e49100;
  }
}

.member {
  .avatar {
    border-radius: 50%;
  }

  .name, .position {
    font-size: 1rem;
    font-weight: bold;
  }

}
</style>
