<template>
  <a
    v-if="external"
    :href="external"
  >
    <slot />
  </a>
  <router-link
    v-else
    v-bind="$props"
  >
    <slot />
  </router-link>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'

export default @Component({})
class ExternalLink extends Vue {
  @Prop() external
  @Prop() to
}
</script>

<style lang="scss" scoped>

</style>
