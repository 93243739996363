import request from '@/utils/request'

export function getTrackRecords () {
  return request.get('/track-records', {
    ignoreLocale: true,
    params: {
      _sort: 'date:DESC'
    }
  })
}
